import React from "react";
import { NavLink } from "react-router-dom"

export function ChapterPicker() {
  return (
    <ul id="chapter-picker">
      {[1, 2, 3, 4, 5].map((idx) => {
        return (
          <li key={idx} > <NavLink to={`${idx}`}>Chapter {idx} </NavLink> </li>
        )
      })}
    </ul>
  )
}