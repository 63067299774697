import React from "react";

export function About() {
  return (
    <div className="main-content">
      <p>
        This is my first book. Please enjoy!
      </p>
    </div>
  )
}