import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { prevNextChapterDisplays } from "../utils";
import { useSwipeable } from "react-swipeable";


export function NextPrevNav(props) {
  const { currentChapter, finalChapterIdx } = props

  const { left, right } = prevNextChapterDisplays(currentChapter, finalChapterIdx)

  return (
    <div id="next-prev-nav">
      <span className="nav-element vert-center-text">
        {left && <NavLink to={`/chapters/${left}`}>&larr; previous</NavLink>}
      </span>
      <span className="nav-element vert-center-text"></span>
      <span className="nav-element vert-center-text">
        {right && <NavLink to={`/chapters/${right}`}>next &rarr;</NavLink>}
      </span>


    </div>
  )
}
