
// Chapters are 1-indexed
export const prevNextChapterDisplays = (currentChapterIdString, final_chapter_idx) => {
  if (!parseInt(currentChapterIdString)) {
    throw new Error(
      `prevNextChapterDisplays: invalid arg: ${currentChapterIdString} type: ${typeof currentChapterIdString}`
    )
  }
  const currentChapter = parseInt(currentChapterIdString)

  if (currentChapter === 1) {
    return { left: undefined, right: 2 }
  }
  if (currentChapter === final_chapter_idx) {
    return { left: final_chapter_idx - 1, right: undefined }
  }
  return { left: currentChapter - 1, right: currentChapter + 1 }
}