import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { SVG_HOME } from "../constants";



function Svg(props) {
  return (
    <svg
      className="svg-base"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 1100 700"
    >
      <path d={props.pathd} />
    </svg>
  )
}

function Nav() {
  return (
    <nav>
      <span className="nav-element vert-center-text">
        <NavLink to="/" className="title-container">
          <Svg className="" pathd={SVG_HOME} />
        </NavLink>
      </span>
      <span className="nav-element vert-center-text">
        <NavLink to="about">About</NavLink>
      </span>

      <span className="nav-element vert-center-text">
        <NavLink to="chapters">Chapters</NavLink>
      </span>

    </nav >
  )
}

function Footer() {
  return (
    <div id="footer">
      Copyright 2024 Peter Kong
    </div>
  )
}

export default function MainLayout() {
  return (
    <div id='max-width-container'>
      <Nav />
      <Outlet />
      <Footer />
    </div>
  )
}